import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { messageActionCreators } from '../../stores/message-store';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from "react-toastify";

class MessageModal extends Component {
    static displayName = MessageModal.name;

    constructor(props) {
        super(props)
        this.state = {
            message: {
                From: '',
                Subject: '',
                Content: '',
                Phone: '',
                Email: ''
            }
        }

        this.handleMessageChange = this.handleMessageChange.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
    }

    async getUserData() {
        await fetch('https://geolocation-db.com/json/')
            .then(resp => resp.json())
            .then((data) => {
                let message = { ...this.state.message }
                message.CountryCode = data['country_code'];
                message.CountryName = data['country_name'];
                message.City = data['city'];
                message.State = data['state'];
                message.Postal = data['postal'];
                this.setState({ message })
            })
            .catch(() => {
                //do nada - likely they are using a browser or tool that blocks geotracking, which is all good
            })
    }

    sendMessage() {
        this.setState({ messageFromErr: '', messageContentErr: '', messagePhoneErr: '', messageEmailErr: '' }, () => {
            let hasError = false
            if (!this.state.message.From) {
                this.setState({ messageFromErr: "You must specify who the message is from." })
                hasError = true;
            }
            if (!this.state.message.Content) {
                this.setState({ messageContentErr: "You must provide a message." })
                hasError = true;
            }
            if (this.state.message.Phone && !/^\(\d{3}\)\s\d{3}\-\d{4}$/.test(this.state.message.Phone)) {
                this.setState({ messagePhoneErr: "If providing a phone number, it must be in the form of (###) ###-####." })
                hasError = true;
            }
            if (this.state.message.Email && !/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(this.state.message.Email)) {
                this.setState({ messageEmailErr: "If providing an email, it must be valid." })
                hasError = true;
            }

            if (hasError) { return; }

            this.setState({ sendingEmail: true }, async () => {
                if (!this.state.message.CountryName) {
                    await this.getUserData();
                }

                const toastDisappearTime = 2500;
                let successfulSend = false;
                this.props.actions.sendMessage(this.state.message)
                    .then(async () => {
                        successfulSend = true;
                        toast.success("Message sent", {
                            onOpen: () => {
                                setTimeout(() => {
                                    toast.dismiss();
                                }, toastDisappearTime);
                            }
                        })
                        let message = { ...this.state.message }
                        message.From = ""
                        message.Subject = ""
                        message.Content = ""
                        message.Phone = ""
                        message.Email = ""
                        if (this.props.toggleSettings) this.props.toggleSettings();
                        this.props.toggleMessageModal();
                        await this.setState({
                            sendingEmail: false,
                            message
                        })
                    })
                    .catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        else if (!successfulSend) {
                            toast.error("Failed to send message", {
                                onOpen: () => {
                                    setTimeout(() => {
                                        toast.dismiss();
                                    }, toastDisappearTime);
                                }
                            })
                        }
                        this.setState({
                            sendingEmail: false
                        })
                    })
            })
        })
    }

    handleMessageChange(state, val) {
        let message = { ...this.state.message };
        if (state === "Phone") { val = this.formatPhoneNumber(val) }
        message[state] = val
        this.setState({ message })
    }

    formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early

        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    render() {
        const { open } = this.props;
        const { message, messageFromErr, messageContentErr, messagePhoneErr, messageEmailErr, sendingEmail } = this.state;

        return (

            <Modal isOpen={open} centered toggle={() => this.props.toggleMessageModal}>
                <ModalHeader style={{ color: 'black' }}>
                    Send A Message
                </ModalHeader>
                <ModalBody>
                    <div className="message_flex">
                        <div className="message_from_container">
                            <label htmlFor="message_from">From</label>
                            <input id="message_from" placeholder="e.g. Your Name" name="name" autoComplete="on" value={message.From} onChange={(e) => this.handleMessageChange("From", e.target.value)} />
                            {messageFromErr && <span className="text-danger">{messageFromErr}</span>}
                        </div>
                        <div className="message_to_container">
                            <label htmlFor="message_to">To</label>
                            <input id="message_to" disabled="disabled" placeholder="Cameron" />
                        </div>
                    </div>
                    <br />
                    <label htmlFor="message_subject">Subject</label>
                    <input id="message_subject" placeholder="(Optional)" value={message.Subject} onChange={(e) => this.handleMessageChange("Subject", e.target.value)} />
                    <br />
                    <label htmlFor="message_content">Message</label>
                    <textarea id="message_content" placeholder="Message" value={message.Content} onChange={(e) => this.handleMessageChange("Content", e.target.value)} />
                    {messageContentErr && <span className="text-danger">{messageContentErr}</span>}
                    <br />
                    <div>
                        <span>Want me to get back to you? Leave a phone number and/or email.</span>
                        <div className="message_flex">
                            <div className="message_phone_container">
                                <label htmlFor="message_phone">Phone</label>
                                <input id="message_phone" name="phone" type="tel" placeholder="Phone #" value={message.Phone} onChange={(e) => this.handleMessageChange("Phone", e.target.value)} />
                                {messagePhoneErr && <span className="text-danger">{messagePhoneErr}</span>}
                            </div>
                            <div className="message_email_container">
                                <label htmlFor="message_email">Email</label>
                                <input id="message_email" name="email" type="email" placeholder="Email" value={message.Email} onChange={(e) => this.handleMessageChange("Email", e.target.value)} />
                                {messageEmailErr && <span className="text-danger">{messageEmailErr}</span>}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={this.sendMessage} className="tiktok-effect" disabled={sendingEmail}>{sendingEmail ? 'Sending Message...' : 'Send Message'}</button>
                    <button onClick={() => this.props.toggleMessageModal()} className="btn btn-secondary" disabled={sendingEmail}>Close</button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default connect(
    (state) => {
        const { message } = state;
        return {
            message
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, messageActionCreators), dispatch)
        }
    }
)(MessageModal)