import { useEffect, useRef, useState } from 'react';
import Heading from './components/atoms/Heading/Heading';
import Span from './components/atoms/Span/Span';
import Hobbies from './components/pages/Hobbies';
import Portfolio from './components/pages/Portfolio';
import { Resume } from './components/pages/Resume';
import Skills from './components/pages/Skills';
import './stylesheets/App.css';
import './stylesheets/mobile.css';
import 'animate.css';
import MessageModal from './components/pages/MessageModal';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const name = useRef();
  const content = useRef();

  useEffect(() => {
    setTimeout(() => {
      name.current.classList.add('animate__animated', 'animate__pulse');
      setTimeout(() => {
        setShowTypewriter(true);
      }, 600)
    }, 750)
  }, [])

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.0025
  }

  const callbackFn = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting)
      setShowButton(true);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options);
    if (content.current) observer.observe(content.current)

    return () => {
      if (content.current) observer.unobserve(content.current)
    }
  }, [content, options]);

  const scrollToStart = () => {
    content.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="app-container">
      <ToastContainer style={{ textAlign: "center" }}
        position="bottom-right"
        hideProgressBar={false}
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <div className="splash organized-section ta-center">
        <div className="push-up" style={{ maxWidth: '94%', margin: 'auto' }}>
          <Heading level={1} className="tiktok-effect lg m-0 animate__animated animate__wobble">Hi!</Heading>
          <Heading level={2} className="m-0">My name is <span ref={name} className="tiktok-effect lg" style={{ display: 'inline-block' }}>Cameron Jones</span>.</Heading>
          <Heading level={3} className="m-0">I'm a&nbsp;{showTypewriter && <Span className="typewriter role color-primary" />}</Heading>
        </div>
        <div className="learn-more-container">
          <div className="learn-more" onClick={() => scrollToStart()}><span className="arrow down" /><span className="tiktok-effect">learn more</span><span className="arrow down" /></div>
        </div>
      </div>
      <div ref={content} className="constrained-container">
        <Portfolio />
        <Hobbies />
        <Skills />
        <Resume />
      </div>
      <MessageModal open={showModal} toggleMessageModal={() => setShowModal(!showModal)} />
      {showButton && <div id="message-button" className="animate__animated animate__heartBeat">
        <button className="btn btn-sm tiktok-effect send-msg-btn" onClick={() => setShowModal(!showModal)}>Send A Message</button>
      </div>
      }
    </div>
  );
}

export default App;
