import { useEffect, useRef } from "react";
import Heading from "../atoms/Heading/Heading";

const Skills = (props) => {
    const listRef = useRef();
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    }

    const callbackFn = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting)
            listRef.current.classList = [...listRef.current.classList].filter(c => c !== "v-hidden").concat('animate__animated', 'animate__bounceInLeft').join(" ");
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFn, options);
        if (listRef.current) observer.observe(listRef.current)

        return () => {
            if (listRef.current) observer.unobserve(listRef.current)
        }
    }, [listRef, options])

    return (
        <div className="organized-section">
            <div className="card-section no-details">
                <Heading level={2} className="m-0 ta-center tiktok-effect section-header">Skills</Heading>
                <ul ref={listRef} className="skills text-left break-up-4 v-hidden">
                    <li>React</li>
                    <li>C# / .NET Core</li>
                    <li>Azure</li>
                    <li>Node.js</li>
                    <li>iOS Dev</li>
                    <li>Android Dev</li>
                    <li>Java</li>
                    <li>Grails</li>
                    <li>Apache Groovy</li>
                    <li>Transact-SQL</li>
                    <li>SSMS</li>
                    <li>Protractor</li>
                    <li>xUnit.net</li>
                    <li>Javascript</li>
                    <li>Visual Studio</li>
                    <li>CSS</li>
                </ul>
            </div>
        </div>
    )
}

export default Skills;