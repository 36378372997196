import React from 'react';
import './Card.css';
import Heading from '../../atoms/Heading/Heading';
import Paragraph from '../../atoms/Paragraph/Paragraph';

const Card = ({ className, img, name, stack, description, onClick }) => (
    <div className={`my-card ${name?.toLowerCase()}`} onClick={onClick}>
        <div className="overlay">
            <div className="content">
                <Heading className="content-header mv-sm" level={3}>{name}</Heading>
                <Paragraph className="content-stack m-0">{stack}</Paragraph>
                <Paragraph className="content-note m-0">(click to learn more)</Paragraph>
            </div>
        </div>
        <img src={img} alt="Avatar" />
    </div>
)

export default Card;