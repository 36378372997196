import Heading from '../atoms/Heading/Heading';
import CardContainer from '../organisms/Card-Container/CardContainer';

const hobbies = [
    {
        id: 1,
        name: "Nature/Travel",
        heading: <Heading level={2} className={"app mv-sm"}>Nature/Travel</Heading>,
        logo: require('../../assets/cameron-grand-teton.jpeg'),
        body: (props) => <>
            <div>
                <p className="m-0 mb-sm">I've always been fascinated by "the great outdoors" and seeing what the massive, broad world has to offer, but I didn't do a ton of exploring until I reached college.
                    It was only then that I started to pursue my passion for hiking, international travel, etc.</p><p className="m-0 mb-sm">Once I began, though, I couldn't get enough — since graduating, I have continued to pursue this passion, most notably by traveling
                        around the United States in 2021 by car with my dog Murph, a Border Collie rescue from the Bahamas. I then traveled with Murph again in 2022 through the Northeastern United States and Canada.</p>
                <div className="nature-images">
                    <div>
                        <figure className="three-three">
                            <img src={require('../../assets/cameron-grand-canyon.jpeg')} alt="Grand Canyon, Arizona" onLoad={props.loadedImage} />
                            <figcaption>Grand Canyon, Arizona</figcaption>
                        </figure>
                    </div>
                    <div>
                        <figure className="three-three">
                            <img src={require('../../assets/lake-tahoe.jpeg')} alt="Lake Tahoe, California" onLoad={props.loadedImage} />
                            <figcaption>Lake Tahoe, California</figcaption>
                        </figure>
                    </div>
                    <div>
                        <figure className="three-three">
                            <img src={require('../../assets/crater-lake.jpeg')} alt="Crater Lake, Oregon" onLoad={props.loadedImage} />
                            <figcaption>Crater Lake, Oregon</figcaption>
                        </figure>
                    </div>
                    <div>
                        <figure className="three-twofive" >
                            <img src={require('../../assets/acadia-hike.jpeg')} alt="Acadia National Park, Maine" onLoad={props.loadedImage} />
                            <figcaption>Acadia National Park, Maine</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    },
    {
        id: 2,
        name: "Reading",
        heading: <Heading level={2} className={"app mv-sm"}>Reading</Heading>,
        logo: require('../../assets/the_brain.jpg'),
        body: (props) => <>
            <div className="book-genres-container">
                <p className="m-0 mb-sm">An easy way for me to wind down and relax is to pick up a book. Typical genres for me include:</p>
                <div className="book-genres">
                    <div className="book-genre">
                        <span className="genre-header">neuroscience/psychology</span>
                        <ul>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/25776132-the-brain">The Brain: The Story of You</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/en/book/show/9827912-incognito">Incognito: The Secret Lives of the Brain</a></em></li>
                        </ul>
                    </div>
                    <div className="book-genre">
                        <span className="genre-header">philosophy</span>
                        <ul>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/30659.Meditations">Meditations</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/4069.Man_s_Search_for_Meaning">Man's Search for Meaning</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6596.The_Four_Agreements">The Four Agreements</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6708.The_Power_of_Now">The Power of Now</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/2547.The_Prophet">The Prophet</a></em></li>
                        </ul>
                    </div>
                    <div className="book-genre">
                        <span className="genre-header">fiction</span>
                        <ul>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/18144590-the-alchemist">The Alchemist</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/3636.The_Giver">The Giver</a></em></li>
                            <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/43708884-the-boy-the-mole-the-fox-and-the-horse">The Boy, the Mole, the Fox, and the Horse</a></em></li>
                        </ul>
                        <small>(yes, that last one is a children's book, and it's incredible)</small>
                    </div>
                </div>
                <div>
                    <figure className="three-onefive">
                        <img src={require('../../assets/the_brain.jpg')} alt="The Brain" className="no-border-radius" onLoad={props.loadedImage} />
                        <figcaption><em>The Brain: The Story of You</em></figcaption>
                    </figure>
                    <figure className="three-onefive">
                        <img src={require('../../assets/the_giver.jpg')} alt="The Giver" className="no-border-radius" onLoad={props.loadedImage} />
                        <figcaption><em>The Giver</em></figcaption>
                    </figure>
                </div>
            </div>
        </>
    },
    {
        id: 3,
        name: "Sports",
        heading: <Heading level={2} className={"app mv-sm"}>Sports</Heading>,
        logo: require('../../assets/states.jpeg'),
        body: (props) => <>
            <div>
                <p className="m-0 mb-sm">A highly active kid, I began playing soccer at around six years old and still do so today. I have also spent time as a volunteer assistant with the Mills Godwin High School Boys Soccer program.
                    As far as professional sports go, I'm a massive Baltimore Ravens fan, and enjoy watching the NBA and Premier League without as much significant interest in any one specific team.</p>
                <figure className="three-threefive">
                    <img src={require('../../assets/states.jpeg')} alt="Virginia 5A State Champions (2015)" onLoad={props.loadedImage} />
                    <figcaption>Virginia 5A State Champions (2015)</figcaption>
                </figure>
            </div>
        </>
    },
    {
        id: 4,
        name: "Animals",
        heading: <Heading level={2} className={"app mv-sm"}>Animals</Heading>,
        logo: require('../../assets/cameron-murph.jpeg'),
        body: (props) => <>
            <div>
                <p className="m-0 mb-sm">I am an animal enthusiast. Aside from growing up with bunnies, hamsters, guinea pigs, birds, and more,
                    I also spent a brief period of time before COVID-19 volunteering at a local nonprofit, no-kill humane society.</p>
                <figure className="three-three">
                    <img src={require('../../assets/cameron-murph.jpeg')} alt="Murphy" onLoad={props.loadedImage} />
                    <br /><figcaption>Murphy</figcaption>
                </figure>
            </div>
        </>
    }
];

const Hobbies = () => {
    return (
        <div className="organized-section">
            <CardContainer cards={hobbies} header={"Hobbies/Passions"} />
        </div>
    );
}

export default Hobbies;
