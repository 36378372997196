import React, { Component, createRef } from 'react';
import Heading from '../atoms/Heading/Heading';
import '../molecules/Card/OldCard.css';

let observer;
export class Resume extends Component {
    static displayName = Resume.name;
    constructor(props) {
        super(props)

        this.state = {
            showTweets: false
        }

        this.toggleTweets = this.toggleTweets.bind(this);
        this.ref = createRef();
    }

    toggleTweets() {
        this.setState({ showTweets: !this.state.showTweets })
    }

    callbackFn = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !this.ref.current.classList.contains("animate__animated"))
            this.ref.current.classList = [...this.ref.current.classList].filter(c => c !== "v-hidden").concat('animate__animated', 'animate__fadeInRight').join(" ");
    }

    componentDidMount() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.0
        };

        this.observer = new IntersectionObserver(this.callbackFn, options);

        if (this.ref.current) {
            this.observer.observe(this.ref.current);
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        return (
            <div ref={this.ref} id="resume" className="page section-grouping resume-page v-hidden">
                <div className="resume-wrapper" />
                <br />
                <br />
                <div>
                    <Heading level={2} className="mv-sm ta-center tiktok-effect section-header">Résumé</Heading>
                    <a rel="noopener noreferrer" target="_blank" href={require('../../assets/Resume.pdf')} download="Cameron_Jones_Resume.pdf" className="tiktok-effect download-button">Download PDF Version</a>
                    <br />
                    <br />
                    <div>
                        <div className="old-card">
                            <div className="old-card-header">
                                <figure className="two-four old-card-img-top m-auto">
                                    <img src={require('../../assets/vmi.jpeg')} alt="VMI" onLoad={this.props.loadedImage} />
                                </figure>
                            </div>
                            <div className="old-card-body">
                                <h5 className="old-card-title">Let's start with college...</h5>
                                <div className="old-card-text">
                                    <div className="old-card-text-header"><span>Virginia Military Institute (2015-2019)</span></div>
                                    <ul className="text-left">
                                        <li><span className="list-title">Bachelor's of Science (Psychology)</span></li>
                                        <li><span className="list-title">Distinguished Graduate with Honors</span></li>
                                        <li><span className="list-title">Graduated in the top 25 of the 2019 Class</span></li>
                                        <li><span className="list-title">GPA</span>: 3.85 overall, 4.0 major</li>
                                        <li><span className="list-title">Graduate Record Examination (GRE)</span>:</li>
                                        <ul>
                                            <li><span className="list-title">Analytical Writing</span>: 92nd Percentile nationwide</li>
                                            <li><span className="list-title">Verbal Reasoning</span>: 88th Percentile nationwide</li>
                                            <li><span className="list-title">Quantitative Reasoning</span>: 80th Percentile nationwide</li>
                                        </ul>
                                        <li><span className="list-title">Major Field Test (MFT) - Psychology</span>: 99th Percentile nationwide</li>
                                        <li><span className="list-title">Psi Chi (International Honors Society for Psychology)</span>: 2017-2019</li>
                                        <li><span className="list-title">Research</span>: Conducted six scientific research studies. Areas of research included, but was not limited to, memory, biases, and life satisfaction.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="old-card">
                            <div className="old-card-header">
                                <figure className="two-two old-card-img-top m-auto">
                                    <img src={require('../../assets/core.jpeg')} alt="Core Consulting" onLoad={this.props.loadedImage} className="no-border-radius" />
                                </figure>
                            </div>
                            <div className="old-card-body">
                                <h5 className="old-card-title">Core Consulting</h5>
                                <div className="old-card-text">
                                    <div className="old-card-text-header">
                                        <span>Software Developer</span>
                                        <br />
                                        <span className="time-span">(June 2019 - Present)</span>
                                    </div>
                                    <ul className="text-left">
                                        <li>Involvement in two multi-year projects, one in the private sector with an insurance agency and one in the public sector with a state agency.</li>
                                        <li>Assisting in a client's multi-year transition from a COBOL-based desktop application to a Groovy/Java-based web application.</li>
                                        <ul>
                                            <li>Building automated UI and API test scripts (unit tests as well as end-to-end integration tests) using Protractor/Node.js/Selenium, thereby allowing for automated regression testing throughout the project lifespan.</li>
                                        </ul>
                                        <li>Assisting in a client's multi-year transition from a COBOL-based web application to a C#-based web application hosted on Azure.</li>
                                        <ul>
                                            <li>Utilizing React.js within an agile .NET environment to deliver a dynamic and responsive user experience according to stakeholder needs and wishes.</li>
                                            <li>Developing and maintaining over 50 C# microservice APIs, utilizing modern industry best practices and leveraging a strong understanding of the underlying SQL database and its schema, stored procedures, etc. to deliver scalable, secure, and efficient solutions for complex business requirements.</li>
                                            <li>Maintaining over 50 pipelines responsible for the nightly CI/CD of each microservice to each application environment, as well as the ensuing microservice regressions automatically run to ensure CI/CD success, security, and application performance in each environment.</li>
                                            <li>Building automated UI and API test scripts using xUnit and RestSharp.</li>
                                            <li>Developing and maintaining a user interface (separate from the primary application) built using React.js and connected (via a C#-based API) to xUnit.net automation scripts, allowing for complex tests/data-creation/etc. to be customizably parameterized and quickly run by front-end users in any application environment.</li>
                                            <li>Developing an end-to-end, scenario-based automation script that exhibits capabilities to be displayed to stakeholders.</li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="old-card">
                            <div className="old-card-header">
                                <figure className="two-two old-card-img-top m-auto">
                                    <img src={require('../../assets/data-directions.jpeg')} alt="Data Directions" onLoad={this.props.loadedImage} className="no-border-radius" />
                                </figure>
                            </div>
                            <div className="old-card-body">
                                <h5 className="old-card-title">Data Directions</h5>
                                <div className="old-card-text">
                                    <div className="old-card-text-header">
                                        <span>Software Developer</span>
                                        <br />
                                        <span className="time-span">(February 2022 - May 2023)</span>
                                    </div>
                                    <ul className="text-left">
                                        <li>Lead developer in transitioning a Fortune 500 client's web application into compliance with Web Content Accessibility Guidelines (WCAG).</li>
                                        <ul>
                                            <li>Addressed hundreds of issues, as identified by Accessibility 360, including, but not limited to, the absence of alt tags on <code>&lt;img&gt;</code> elements, minimum color combination contrasts, telephone number markup, and form adherence (proper labels, required attributes, etc.).</li>
                                            <li>Created scripts that would be run on all pages and allow these issues to be automatically addressed without manual effort from the client's development team.</li>
                                            <ul>
                                                <li>One lightweight script identifies all <code>&lt;img&gt;</code> elements that do not have an alt tag (using Regex), and then identifies and applies an appropriate tag to each. This not only fixed all existing instances of this issue, but also protects against any future instances wherein the development
                                                    team adds a new <code>&lt;img&gt;</code> element without an alt tag.</li>
                                                <li>Another script identifies all phone numbers on the page that are not marked up as links (useful in allowing mobile users to click on the phone number), and wraps each in individual <code>&lt;a&gt;</code> elements with proper <code>href="tel:..."</code> attributes. Again, this works as both a retroactive and proactive solution.</li>
                                            </ul>
                                        </ul>
                                        <li>Development role in multiple projects with one of the largest East Coast full service equipment and tractor dealerships in the agriculture, construction, forestry, paving, and mining industries.</li>
                                        <ul>
                                            <li>Designed, executed, and maintained an automated testing strategy using xUnit & Selenium for three web applications that had previously relied on manual test efforts.</li>
                                            <li>Built unit tests for all facets of the application, while also utilizing the same components in an end-to-end test that ensured full app functionality before releasing to end users.</li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="old-card">
                            <div className="old-card-body">
                                <h5 className="old-card-title no-header">Personal Work</h5>
                                <div className="old-card-text">
                                    <div className="old-card-text-header">
                                        <span className="link">Baltimordle</span>
                                    </div>
                                    <ul className="text-left">
                                        <li>My most popular web application and game thus far, having been played by users across more than 20 countries and 40 U.S. states in just its first 24 hours alone.</li>
                                        <li>A daily mystery game wherein users try to guess a current Baltimore Ravens player.</li>
                                        <li>After each guess (max of 6), users are given hints.</li>
                                        <ul>
                                            <li>For instance, if the user guesses a player who is 24 years old and wears number 52, and the correct player is 24 years old and wears number 50, the user's guessed player will have its age highlighted green, indicating an exact match, and the player's number highlighted yellow,
                                                indicating that the number is within 2.</li>
                                        </ul>
                                        <li>Due to the support it received, I transitioned Baltimordle into an iOS app, built using React Native.</li>
                                        <li>The web application was built using ReactJS and hosted on Azure.</li>
                                    </ul>
                                    <br />
                                    <hr />
                                    <div className="old-card-text-header">
                                        <span className="link">Sportle</span>
                                        <div style={{ fontSize: '100%' }}>
                                            <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/us/app/sportle-games/id6476887936" className="link">iOS</a> / <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.cameron.sportle" className="link">Android</a> / <a rel="noopener noreferrer" target="_blank" href="https://sportlegames.com" className="link">Web</a>
                                        </div>
                                    </div>
                                    <ul className="text-left">
                                        <li>With the rise of Baltimordle came requests for it to be built for other NFL teams, as well. Many even asked for it to be built for teams in other sports! So, I created Sportle.</li>
                                        <li>Built using the same ideas as Baltimordle, but applied to all teams in the following leagues:</li>
                                        <ul>
                                            <li>NFL, NBA, WNBA, NHL, MLB, MLS, NWSL, La Liga, and Premier League</li>
                                        </ul>
                                        <li>There is also an exciting "All" sports section, which tests users who believe they are knowledgable in multiple sports.</li>
                                        <ul>
                                            <li>This section has a daily mystery player just like the others, but is chosen from ANY team in ANY of the 9 aforementioned sports leagues.</li>
                                        </ul>
                                        <li>In March 2024, I replaced the Baltimordle iOS app with <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936">iOS</a> & <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">Android</a> versions of Sportle, built using the Flutter framework.</li>
                                    </ul>
                                    <br />
                                    <hr />
                                    <div className="old-card-text-header">
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.droidle.app" className="link">Droidle.app</a>
                                    </div>
                                    <ul className="text-left">
                                        <li>Another ReactJS web application that allows users to face a bot in a series of challenging word games.</li>
                                        <li>The bot is programmed to be any one of three user-selected difficulties (easy, medium, hard), giving way for the user to work their way up.</li>
                                        <li>The application is also hosted on Azure.</li>
                                    </ul>
                                    <br />
                                    <hr />
                                    <div className="old-card-text-header">
                                        <a rel="noopener noreferrer" target="_blank" href="https://www.recommend-me.io" className="link">Recommend-Me.io</a>
                                    </div>
                                    <ul className="text-left">
                                        <li>The very first web application I built entirely on my own.</li>
                                        <li>A .NET Core web application that provides users with recommendations for music, movies, TV shows, and podcasts, based on a variety of user inputs including mood and preferences.</li>
                                        <li>The UI is React based while the APIs are coded using C#.</li>
                                        <li>CI/CD is accomplished using GitHub Actions and Azure App Services, where the application is hosted.</li>
                                    </ul>
                                    <br />
                                    <hr />
                                    <div className="old-card-text-header">
                                        <span className="link">Twitter Bots</span>
                                    </div>
                                    <ul className="text-left">
                                        <li>Created multiple simple Twitter/X bots using Python.</li>
                                        <ul>
                                            <li>@NFLDataBot, for example, allows users to request a variety of NFL data:</li>
                                            <ol>
                                                <li>Specific play from a given game in NFL history</li>
                                                <ul><li>Includes game date, teams playing, quarter, time left, down and distance, yard line, formation, pass/run, yards gained, play result, etc.</li></ul>
                                                <li>Weather from a given game in NFL history</li>
                                                <ul><li>Includes game date, teams playing, temperature, humidity, wind, wind chill, and final score.</li></ul>
                                                <li>Combine results</li>
                                                <ul><li>Includes player name, position, combine year, college, height, weight, 40 yard dash time, 3 cone time, vertical jump, broad jump, bench press, and draft round/pick.</li></ul>
                                                <li>Player information</li>
                                                <ul><li>Includes player name, birth date, death date when applicable, hometown, college, draft team, draft round, draft year, position, etc.</li></ul>
                                            </ol>
                                        </ul>
                                        <li>All such bots have now been shut down as Twitter/X has cut off free access to its API.</li>
                                        <br />
                                    </ul>
                                    <div className="toggle-tweets-btn">
                                        <button className="tiktok-effect" onClick={this.toggleTweets}>{!this.state.showTweets ? 'Show' : 'Hide'} Example Tweets</button>
                                    </div>
                                    <br />
                                    <div className={`tweets`} style={this.state.showTweets ? {} : { display: 'none' }}>
                                        <blockquote className="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">September 09, 2018 GB vs CHI<br />2:29 left in the 4th quarter<br />3rd and 10 on GB 25.0 yard line<br />(SHOTGUN) GB passed SHORT MIDDLE for 75.0 yards.<br />TOUCHDOWN!<a href="https://twitter.com/hashtag/NFL?src=hash&amp;ref_src=twsrc%5Etfw">#NFL</a> <a href="https://twitter.com/hashtag/DaBears?src=hash&amp;ref_src=twsrc%5Etfw">#DaBears</a> <a href="https://twitter.com/hashtag/GoPackGo?src=hash&amp;ref_src=twsrc%5Etfw">#GoPackGo</a> <a href="https://t.co/FkVAby3o9Q">pic.twitter.com/FkVAby3o9Q</a></p>&mdash; NFL Data Bot (@NFLDataBot) <a href="https://twitter.com/NFLDataBot/status/1489032243700015106?ref_src=twsrc%5Etfw">February 3, 2022</a></blockquote>
                                        <blockquote className="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">WEATHER<br /><br />1/10/1982<br />San Diego Chargers @ Cincinnati Bengals<br />-6 degrees- relative humidity 51%- wind 24 mph- wind chill -32<br />Final Score...<br />San Diego Chargers: 7<br />Cincinnati Bengals: 27<a href="https://twitter.com/hashtag/NFL?src=hash&amp;ref_src=twsrc%5Etfw">#NFL</a> <a href="https://twitter.com/hashtag/RuleTheJungle?src=hash&amp;ref_src=twsrc%5Etfw">#RuleTheJungle</a> <a href="https://twitter.com/hashtag/BoltUp?src=hash&amp;ref_src=twsrc%5Etfw">#BoltUp</a> <a href="https://t.co/IaA49ZwNpt">pic.twitter.com/IaA49ZwNpt</a></p>&mdash; NFL Data Bot (@NFLDataBot) <a href="https://twitter.com/NFLDataBot/status/1485473791094448131?ref_src=twsrc%5Etfw">January 24, 2022</a></blockquote>
                                        <blockquote className="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">COMBINE<br /><br />Odell Beckham (WR - 2014)<br />College: LSU<br />5 feet 11.0 inches, 198 pounds<br />40yd: 4.43s<br />3 cone: 6.69s<br />Vertical: 38.5in<br />Broad Jump: 122in<br />Bench: 7 reps<br /><br />Pick # 12 in round 1<a href="https://twitter.com/hashtag/NFL?src=hash&amp;ref_src=twsrc%5Etfw">#NFL</a> <a href="https://twitter.com/hashtag/LSU?src=hash&amp;ref_src=twsrc%5Etfw">#LSU</a> <a href="https://t.co/B3Gdnw2dPv">pic.twitter.com/B3Gdnw2dPv</a></p>&mdash; NFL Data Bot (@NFLDataBot) <a href="https://twitter.com/NFLDataBot/status/1485451852690989064?ref_src=twsrc%5Etfw">January 24, 2022</a></blockquote>
                                        <blockquote className="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">This Twitter account is pretty neat <a href="https://t.co/4DRisGQo5f">https://t.co/4DRisGQo5f</a></p>&mdash; Damien Williams (@dayman23) <a href="https://twitter.com/dayman23/status/1488353079615442950?ref_src=twsrc%5Etfw">February 1, 2022</a></blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
                <br />
            </div>
        );
    }
}
