import { publicRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const sendMessageSuccess = "SEND_MESSAGE_SUCCESS";
const sendMessageFailure = "SEND_MESSAGE_FAILURE";

const initialState = {
    messages: [],
    messagesError: ''
};

export const messageActionCreators = {
    receiveSendMessage: resObj => ({
        type: sendMessageSuccess,
        messages: resObj
    }),

    sendMessageError: (message) => ({
        type: sendMessageFailure,
        messagesError: message
    }),

    sendMessage: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_MESSAGE_API_URL) + '/Message/api/SendMessage', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(messageActionCreators.sendMessageError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(messageActionCreators.receiveSendMessage(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(messageActionCreators.sendMessageError('No content'))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        dispatch(messageActionCreators.sendMessageError('Unknown error'))
                    }
                }).catch(err => Promise.reject(err));
        }
    }

};


export const reducer = (state, action) => {

    state = state || initialState;
    switch (action.type) {
        case sendMessageSuccess:
            return Object.assign({}, state, {
                messages: action.messages,
                messagesError: action.messagesError
            })
        case sendMessageFailure:
            return Object.assign({}, state, {
                messagesError: action.messagesError
            })
        default:
            return state
    }
};