import React from 'react';
import './Heading.css';

const Heading = ({ children, level, className }) => {
    switch (level) {
        case 1:
            return (<h1 className={className}>{children}</h1>);
        case 2:
            return (<h2 className={className}>{children}</h2>);
        case 3:
            return (<h3 className={className}>{children}</h3>);
        case 4:
        default:
            return (<h4 className={className}>{children}</h4>);
    }
}

export default Heading;