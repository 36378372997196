import React, { useEffect, useRef, useState } from 'react';
import Card from '../../molecules/Card/Card';
import './CardContainer.css';
import Heading from '../../atoms/Heading/Heading';

const CardContainer = ({ cards, header, className, ...props }) => {
    const [selectedCard, setSelectedCard] = useState("");

    const container = useRef();

    const toggleSelectedCard = (card, e) => {
        if (e) e.preventDefault();
        if (card) {
            if (container.current)
                container.current.classList = 'push-up animate__animated animate__bounceOutRight';
            setTimeout(() => {
                if (container.current)
                    container.current.classList = 'animate__animated animate__bounceInDown';
                setSelectedCard(card);
            }, 750);
        } else {
            if (container.current)
                container.current.classList = 'animate__animated animate__bounceOutDown';
            setTimeout(() => {
                setSelectedCard("");
                if (container.current)
                    container.current.classList = 'push-up animate__animated animate__bounceInLeft';
            }, 750);
        }
    }

    const listRef = useRef();
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    }

    const callbackFn = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting)
            listRef.current.classList = [...listRef.current.classList].filter(c => c !== "v-hidden").concat('animate__animated', 'animate__bounceInLeft').join(" ");
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFn, options);
        if (listRef.current) observer.observe(listRef.current)

        return () => {
            if (listRef.current) observer.unobserve(listRef.current)
        }
    }, [listRef, options])


    return (
        <div ref={container}>
            {!selectedCard ?
                <div className={`card-section ${header.replaceAll("/", "-").toLowerCase()}`}>
                    <Heading level={2} className="m-0 ta-center tiktok-effect section-header">{header}</Heading>
                    <div ref={listRef} className="card-container v-hidden">
                        {cards.map((card, key) =>
                            <Card key={key} img={card.logo} onClick={(e) => toggleSelectedCard(card, e)} {...card} />
                        )}
                    </div>
                </div>
                :
                <div className={`card-details ${selectedCard.name?.replaceAll("/", "-")?.toLowerCase()}`}>
                    <div className={`section`}>
                        <div className="section-header-wrapper">
                            <span className="close-portfolio js-close-portfolio d-flex align-items-center" onClick={(e) => toggleSelectedCard(null, e)}><span className="close-portfolio-label">Back to {header}</span><span className="icon-close2 wrap-icon-close"></span></span>
                            {selectedCard.heading}
                            {selectedCard.subheading}
                        </div>
                        {selectedCard.body(props)}
                    </div>
                    {selectedCard.stack && <Heading level={4}>Stack: {selectedCard.stack}</Heading>}
                </div>

            }
        </div>
    )
}

export default CardContainer;