import Heading from '../atoms/Heading/Heading';
import CardContainer from '../organisms/Card-Container/CardContainer';

const portfolio = [
    {
        id: 1,
        name: "Sportle",
        heading: <Heading level={2} className={"app mv-sm"}>Sportle</Heading>,
        subheading: <span className="sub-header">Available for the <a target="_blank" rel="noopener noreferrer" href="https://sportlegames.com">Web</a>, <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936">iOS</a>, & <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">Android</a></span>,
        logo: require('../../assets/sportle-iOS.png'),
        images: [
            { src: require('../../assets/Sportle-Ravens.png') },
            { src: require('../../assets/Sportle-Lamar.png') },
            { src: require('../../assets/Sportle-All-Sports.png') },
            { src: require('../../assets/Sportle-LeBron.png') },
            { src: require('../../assets/Sportle-Leaderboard.png') },
        ],
        stack: "React.js, MUI, SASS, Flutter, xcode, Firebase, C#, Azure",
        body: (props) => <div className="list">
            <li className="list-card images">
                <div>
                    <span>Users have 4-6 attempts (depending on the sport) to guess a mystery athelete from any of their favorite teams & leagues, with a new player being chosen every day.</span>
                </div>
                <div className="image-container">
                    <img className="no-border-radius" src={require('../../assets/Sportle-Ravens.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={props.loadedImage} />
                    <img className="no-border-radius" src={require('../../assets/Sportle-Lamar.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={props.loadedImage} />
                </div>
                <span className="icon">1/4</span>
            </li>
            <li className="list-card images">
                <div>
                    <span>There is also an "All Sports" mode that challenges users who believe they are knowledgeable fans of multiple sports, as this game mode chooses a new mystery player daily from ANY league!</span>
                </div>
                <div className="image-container">
                    <img className="no-border-radius" src={require('../../assets/Sportle-All-Sports.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={props.loadedImage} />
                    <img className="no-border-radius" src={require('../../assets/Sportle-LeBron.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={props.loadedImage} />
                </div>
                <span className="icon">2/4</span>
            </li>
            <li className="list-card images">
                <div>
                    <span>Users can compete with each other using the daily and all-time leaderboards for each league & team.</span>
                </div>
                <div className="image-container">
                    <img className="no-border-radius" src={require('../../assets/Sportle-Leaderboard.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={props.loadedImage} />
                </div>
                <span className="icon">3/4</span>
            </li>
            <li className="list-card images">
                <div>
                    <span>UI is built using React.js for the Web and Flutter for iOS and Android. APIs are built using C# / .NET Core. Uses Firebase for database & authentication/authorization. Hosted on Azure.</span>
                </div>
                <div className="image-container rows">
                    <img className="no-border-radius" src={require('../../assets/react.png')} style={{ borderRadius: '25px', maxWidth: '64%' }} alt="React" onLoad={props.loadedImage} />
                    <img className="no-border-radius" src={require('../../assets/flutter.png')} style={{ borderRadius: '25px', maxWidth: '62.5%' }} alt="Flutter" onLoad={props.loadedImage} />
                    <span className="firebase" style={{ margin: 'auto' }} alt="Firebase" onLoad={props.loadedImage} />
                </div>
                <span className="icon">4/4</span>
            </li>
        </div>
    },
    {
        id: 2,
        name: "LIS",
        heading: <a href="https://lis.virginia.gov" target="_blank" rel="noopener noreferrer"><Heading level={2} className={"app mv-sm"}>lis.virginia.gov</Heading></a>,
        logo: require('../../assets/lis_logo.png'),
        stack: "React.js, SASS, C#, SQL, Azure",
        body: (props) => <>
            <div className="list">
                <li className="list-card"><span>Designed to promote transparency and make it easier for citizens, lawmakers, and stakeholders to engage with the legislative process in Virginia.</span><span className="icon">1/4</span></li>
                <li className="list-card"><span>Users can search for specific bills, view legislative calendars, and access committee information, among other features.</span><span className="icon">2/4</span></li>
                <li className="list-card"><span>Provides access to information about the Virginia General Assembly, allowing users to track legislation, view bills, access session documents, and find information about legislators.</span><span className="icon">3/4</span></li>
                <li className="list-card"><span>UI is built using React.js. Over 40 microservices are built using C# / .NET Core. Data are stored and retrieved using SQL. Hosted on Azure.</span><span className="icon">4/4</span></li>
            </div>
            <div className="images">
                <img className="no-border-radius" src={require('../../assets/lis_home.png')} alt="LIS" onLoad={props.loadedImage} />
            </div>
        </>
    },
    {
        id: 3,
        name: "Recommend-Me",
        heading: <a href="https://recommend-me.io" target="_blank" rel="noopener noreferrer"><Heading level={2} className={"app mv-sm"}>Recommend-Me.io</Heading></a>,
        subheading: <span className="sub-header smol">The very first web application I built, Recommend-Me.io is a free service that provides recommendations for music, tv shows, movies, and podcasts.</span>,
        logo: require('../../assets/recommend-me.ico'),
        stack: "React.js, CSS, C#, Azure",
        body: (props) => <>
            <div className="list">
                <li className="list-card"><span>Entering a variety of inputs, such as mood, favorite music artists, favorite actors & actresses, etc., this application provides a quick and easy way to get helpful recommendations.</span><span className="icon">1/3</span></li>
                <li className="list-card"><span>Integrated with Spotify, users are able to quickly and easily create playlists as well as add recommendations to them.</span><span className="icon">2/3</span></li>
                <li className="list-card"><span>UI is built using React.js. APIs are built using C# / .NET Core. Hosted on Azure.</span><span className="icon">3/3</span></li>
            </div>
            <div className="images">
                <img className="no-border-radius" src={require('../../assets/Recommend-Me-Mood.png')} alt="Recommend Me" onLoad={props.loadedImage} />
            </div>
        </>
    },
    {
        id: 4,
        name: "Droidle",
        heading: <a className="app" target="_blank" rel="noopener noreferrer" href="https://droidle.app"><Heading level={2} className={"app mv-sm"}>Droidle.app</Heading></a>,
        subheading: <span className="sub-header smol">Users compete against a bot in a series of challenging word games at a user-chosen level of difficulty.</span>,
        logo: require('../../assets/droidle.ico'),
        stack: "React.js, CSS, Azure",
        body: (props) => <>
            <div className="images">
                <img className="no-border-radius smol" src={require('../../assets/Droidle-Home-2.png')} style={{ borderRadius: '25px' }} alt="Droidle" onLoad={props.loadedImage} />
            </div>
            <div className="list">
                <li className="list-card images">
                    <div>
                        <span>Users may compete head-to-head against a Droidle, trying to be the first to solve a system-generated mystery word.</span>
                    </div>
                    <div className="image-container">
                        <img className="no-border-radius smol" src={require('../../assets/Droidle-One-V-One.png')} alt="Droidle" onLoad={props.loadedImage} />
                    </div>
                    <span className="icon">1/2</span>
                </li>
                <li className="list-card images">
                    <div>
                        <span>Alternatively, users may try to outsmart a Droidle by thinking of a word that they believe the Droidle will not successfully guess.</span>
                    </div>
                    <div className="image-container">
                        <img className="no-border-radius smol" src={require('../../assets/Droidle-Outsmart-2.png')} style={{ borderRadius: '25px' }} alt="Droidle" onLoad={props.loadedImage} />
                    </div>
                    <span className="icon">2/2</span>
                </li>
            </div>
        </>
    }
]

const Portfolio = () => {
    return (
        <div className="organized-section">
            <CardContainer cards={portfolio} header={"Portfolio"} />
        </div>
    );
}

export default Portfolio;
