const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

let abortController = new window.AbortController();
let signal = abortController ? abortController.signal : "";

export const cancelRequest = () => {
    if (abortController)
        abortController.abort();
}

//The only difference between publicRequest and partnerRequest is that partnerRequest checks to see if the token is valid and sends it with the fetch
export const publicRequest = (url, method, headers, body, params) => {
    let config = {
        method: method,
    }
    if (headers) {
        config.headers = headers;
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    if (signal && signal !== "" && signal.aborted === true) {
        //Refresh the abortController because you can't reset the abort property of a signal
        abortController = new window.AbortController();
        signal = abortController ? abortController.signal : "";
    }
    if (signal && signal !== "") {
        config.signal = signal;
    }
    return fetch(url + params, config)
        .then(response => {
            if (response.status === 204) {
                //Some endpoints do not return anything if there is no data to be returned. So NO_CONTENT must be specified as it is not always an error
                //throw [NO_CONTENT, 'No Content'];
                throw Object.assign(
                    new Error('No Content'),
                    { code: 204 }
                );
            } else if (response.status > 399) {
                //There was a request failure. Return the response. Do not try to convert it to a JSON object as there is no guarantee that it is formatted as a json
                return (response.text()
                    .then(text => {
                        console.log(text);
                        return Promise.reject([REQUEST_FAILURE, text])
                    })
                )
            }
            return response

        })
        .then(response =>
            //Our request is successful. Convert it to JSON
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [REQUEST_SUCCESS, data]
        })
        .catch(err => err);
}

